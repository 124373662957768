import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ScrollToTop from "../../ScrollToTop";
import logo_age from '../../../assets/images/Logo-Yellow-White.png';

class Header2 extends React.Component {

  componentDidMount() {
    
    window.scrollTo(0, 0);
  }

  menuClass = 'main-menu-item';

  constructor(props) {
    super(props);
    this.state = {
      activeMenuItems: {},
      activeSubMenuItems: {},
    };
  }

  componentDidMount() {
    const storedActiveMenuItems = JSON.parse(localStorage.getItem('activeMenuItems'));
    const storedActiveSubMenuItems = JSON.parse(localStorage.getItem('activeSubMenuItems'));

    if (storedActiveMenuItems && storedActiveSubMenuItems) {
      this.setState({
        activeMenuItems: storedActiveMenuItems,
        activeSubMenuItems: storedActiveSubMenuItems,
      });
    }

   
    this.setActiveStatesFromURL();
  }

  setActiveStatesFromURL = () => {
    const { pathname } = window.location;

    const updatedActiveMenuItems = {};
    const updatedActiveSubMenuItems = {};

    const menuData = [{
      id: 'menu1',
      label: 'Home',
      link: '/home',
    },
    {
      id: 'menu2',
      label: 'Who We Are',
      subMenus: [{
        id: 'subItem1',
        label: 'Our History',
        link: '/our-history'
      },
      {
        id: 'subItem2',
        label: 'Our Values',
        link: '/our-values'
      },
      {
        id: 'subItem3',
        label: 'Our Sustainability Goals',
        link: '/sustainability'
      },
      {
        id: 'subItem4',
        label: 'Our Commitment to D & I',
        link: '/diversity-inclusion'
      },
      ],
    },
    {
      id: 'menu3',
      label: 'What We Do',
      subMenus: [{
        id: 'subItem5',
        label: 'GCC India',
        link: '/gcc-india'
      },
      {
        id: 'subItem6',
        label: 'BU India',
        link: '/bu-india'
      },
      ],
    },
    {
      id: 'menu4',
      label: 'Our Leaders',
      subMenus: [{
        id: 'subItem7',
        label: 'GCC Leaders',
        link: '/gcc-leaders'
      },
      {
        id: 'subItem8',
        label: 'BU Leaders',
        link: '/bu-leaders'
      },
      ],
    },
    {
      id: 'menu5',
      label: 'PROGRAMMES',
      subMenus: [{
        id: 'subItem9',
        label: 'Leap Programme',
        link: '/leap-programme'
      },
      {
        id: 'subItem10',
        label: 'Brew Programme',
        link: '/brew-programme'
      },
      {
        id: 'subItem11',
        label: 'Global Management Trainee Programme',
        link: '/global-management-trainee-programme'
      },
      {
        id: 'subItem12',
        label: 'Sales Visionaries Programme',
        link: '/sales-visinories-programme'
      },
      {
        id: 'subItem13',
        label: 'Supply Management Traineeship',
        link: '/supply-management-traineeship'
      },
      ],
    },
    {
      id: 'menu6',
      label: 'Our Brands',
      link: '/our-brands',
    },
    {
      id: 'menu7',
      label: 'Careers',
      link: 'https://ab-inbev-gcc.sensehq.com/careers',
      target: '_blank',
    },
    ];

    menuData.forEach((menu) => {
      if (menu.subMenus) {
        if (menu.subMenus.some((subMenu) => subMenu.link === pathname)) {
          updatedActiveMenuItems[menu.id] = true;
          const subMenuItem = menu.subMenus.find((subMenu) => subMenu.link === pathname);
          updatedActiveSubMenuItems[menu.id] = subMenuItem.id;
        }
      } else {
        if (menu.link === pathname) {
          updatedActiveMenuItems[menu.id] = true;
        }
      }
    });

    this.setState({
      activeMenuItems: updatedActiveMenuItems,
      activeSubMenuItems: updatedActiveSubMenuItems,
    });
  };

  handleMenuItemClick = (menuId) => {
    if (menuId !== 'menu7') {
      this.setState(
        (prevState) => {
          const updatedActiveMenuItems = { [menuId]: !prevState.activeMenuItems[menuId] };
          const updatedActiveSubMenuItems = { ...prevState.activeSubMenuItems };
          updatedActiveSubMenuItems[menuId] = null;

          return {
            activeMenuItems: updatedActiveMenuItems,
            activeSubMenuItems: updatedActiveSubMenuItems,
          };
        },
        () => {
          this.saveToLocalStorage();
        }
      );
    }
  };

  handleSubMenuClick = (menuId, subMenuId) => {
    this.setState(
      (prevState) => ({
        activeSubMenuItems: {
          ...prevState.activeSubMenuItems,
          [menuId]: subMenuId,
        },
        activeMenuItems: {
          [menuId]: true, 
          ...Object.keys(prevState.activeMenuItems).reduce((acc, key) => {
            if (key !== menuId) {
              acc[key] = false;
            }
            return acc;
          }, {}),
        },
      }),
      () => {
        this.saveToLocalStorage();
      }
    );
  };

  saveToLocalStorage = () => {
    localStorage.setItem('activeMenuItems', JSON.stringify(this.state.activeMenuItems));
    localStorage.setItem('activeSubMenuItems', JSON.stringify(this.state.activeSubMenuItems));
  };


  render() {
    const { activeMenuItems, activeSubMenuItems } = this.state;

    const menuData = [{
      id: 'menu1',
      label: 'Home',
      link: '/home',
    },
    {
      id: 'menu2',
      label: 'Who We Are',
      subMenus: [{
        id: 'subItem1',
        label: 'Our History',
        link: '/our-history'
      },
      {
        id: 'subItem2',
        label: 'Our Values',
        link: '/our-values'
      },
      {
        id: 'subItem3',
        label: 'Our Sustainability Goals',
        link: '/sustainability'
      },
      {
        id: 'subItem4',
        label: 'Our Commitment to D & I',
        link: '/diversity-inclusion'
      },
      ],
    },
    {
      id: 'menu3',
      label: 'What We Do',
      subMenus: [{
        id: 'subItem5',
        label: 'GCC India',
        link: '/gcc-india'
      },
      {
        id: 'subItem6',
        label: 'BU India',
        link: '/bu-india'
      },
      ],
    },
    {
      id: 'menu4',
      label: 'Our Leaders',
      subMenus: [{
        id: 'subItem7',
        label: 'GCC Leaders',
        link: '/gcc-leaders'
      },
      {
        id: 'subItem8',
        label: 'BU Leaders',
        link: '/bu-leaders'
      },
      ],
    },
    {
      id: 'menu5',
      label: 'PROGRAMMES',
      subMenus: [{
        id: 'subItem9',
        label: 'Leap Programme',
        link: '/leap-programme'
      },
      {
        id: 'subItem10',
        label: 'Brew Programme',
        link: '/brew-programme'
      },
      {
        id: 'subItem11',
        label: 'Global Management Trainee Programme',
        link: '/global-management-trainee-programme'
      },
      {
        id: 'subItem12',
        label: 'Sales Visionaries Programme',
        link: '/sales-visinories-programme'
      },
      {
        id: 'subItem13',
        label: 'Supply Management Traineeship',
        link: '/supply-management-traineeship'
      },
      ],
    },
    {
      id: 'menu6',
      label: 'Our Brands',
      link: '/our-brands',
    },
    {
      id: 'menu7',
      label: 'Careers',
      link: 'https://ab-inbev-gcc.sensehq.com/careers',
      target: '_blank',
    },
    ];

    return (
      <header>
        <ScrollToTop> </ScrollToTop>
        <div className="container-fluid">
          <Navbar fixed="top" className="header-menu" expand="lg" >
            <Navbar.Brand href="/home">
              <img width="102" src={logo_age} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="menu-links" id="basic-navbar-nav" >
              <Nav className="">
                <div className="main-menu-new">
                  {menuData.map((menu) => (
                    <div className="main-menu-new-inner" key={menu.id}>
                      <a
                        href={menu.link || '#'}
                        className={`${this.menuClass} ${activeMenuItems[menu.id] ? 'active' : ''}`}
                        onClick={() => this.handleMenuItemClick(menu.id)}
                      
                      >
                        {menu.label}
                      </a>

                      {menu.subMenus && (
                        <ul className="main-menu-hover" style={{ display: activeMenuItems[menu.id] ? 'block' : 'none' }}>
                          {menu.subMenus.map((subMenu) => (
                            <li key={subMenu.id}>
                              <a
                                href={subMenu.link || '#'}
                                className={activeSubMenuItems[menu.id] === subMenu.id ? 'active' : ''}
                                onClick={() => this.handleSubMenuClick(menu.id, subMenu.id)}
                              >
                                {subMenu.label}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div> {
                /* <Link to="/">Home</Link>   */} {
                /* <Link to="/home">Home</Link> | <Link to="/about">About</Link> | <Link to="/contact">Contact</Link> */} </header>
    );
  }
}
export default Header2;